@import '~antd/dist/antd.css';

.radio-new-order > div > div {
  border-width: 1px !important;
  border-radius: 6px !important;
  padding: 3px !important;
  padding-top: 7px !important;
  padding-left: 7px !important;
}
.timesheettable > div> div> div> div> div>table > thead > tr > th{
  text-align: center;
}
/* .timesheettable > div> div> div> div> div>table > tbody > tr > td{
  text-align: center;
} */
/* .weekend-header{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #00913a;
} */

.table-report-th{
  text-align: right;
}
.table-report-th > span {
  text-align: right !important;
  width: 100%;
}
.report-cell{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.daycell{
  border: 2px solid #d1d1d1;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px !important;
  text-align: center;
  font-size: 12px;
  background-color: #c6d492;
}

.daycell-empty{
  margin: 0 auto;
  display: block;
  width: 24px;
  height: 24px !important;
  border: 2px solid #d3d3d3;
}
.ant-tag{
  font-size: 10px;
}
.v-order-main{
  display: flex;
  flex: 1;
  border: 1px solid #f5f5f5;
  border-radius: 4px;
  margin-bottom: 6px;
  padding: 4px;
}
.v-order-main-num{
  font-size: 14px;
}

table-tbody > tr > td {
  padding: 8px 6px;
}

.rc-color-picker-trigger{
  width: 30px;
  height: 30px;
}
.new-row{
  background-color: #e6ffea;
}
.switch-wrapper {
  position: relative;
  width: 100%;
}
.switch-wrapper > div {
  position: absolute;
  width: 100%;
}
.login-form {
  max-width: 300px;
  margin: 0 auto;
}
.login-background{
  background-color:#fbfbfb;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-logo{
  text-align: center;
  margin-bottom: 30px;
}
.login-logo img{
  max-width: 300px;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
  background-color: #9FBE34;
  border: 0;
  color: #1C1C1C;
  text-transform: uppercase;
}
.login-form-button:hover {
  background-color: #bbd55e;
}
.top-buttons {
  float: right;
  margin-right: 20px;
}
.side-menu{
  color: #ffffff;
}
.ant-menu-item > a{
  display:inline;
}
.logo{
  text-align: center;
  color:#ababab;
  font-weight: 600;
  font-size: 1.5em;
}
.header-div{
  margin-top: 0px !important;
}
.info-text{
  padding: 0.2em 0.4em 0.1em;
}
.ant-upload.ant-upload-select-picture-card{
  width:100%;
}
.rte-height{
  min-height: 300px;
}
.content-div{
  padding: 24px;
  background-color: #fff;
  min-height: 360px;
}
.content-layout{
  padding: 10px;
}
.menu-logo{
  text-align: center;
  margin-bottom: 20px;
  margin-top: 30px;
}
.menu-logo img{
  width: 80px;
  height: 80px;
}
.dark-menu-bg{
  background-color:#1C1C1C;  
}
.ant-menu-item-selected{
  background-color:#9FBE34 !important;  
}
.ant-menu-item-selected a span, .ant-menu-item-selected i {
  color:#000000 !important;  
}
.ant-menu-item-selected{
  border-radius: 5px;
}
.ant-menu{
  padding: 5px;
}

.header-center-row{
  padding-right: 10px;
}
.header-center-row-valign{
  padding-top: 4px;
  padding-right: 10px;
}
.sale-block{
  width: 100%;
}
.sale-header{
  display: flex;
  align-items: center;
  height:30px;
  background-color: #1C1C1C;
  color: #ffffff;
  margin-bottom: 5px;
}
.sale-header-row{
  font-weight: bold;
  padding-left: 10px;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-grow: 1;
}
.sale-header-row-end{
  width: 100px;
}
.sale-header-row-end-item{
  float: right;
  padding-right: 12px;
}
.sale-header-status-new{
  padding-left: 5px;
  color:#c7ffc9;
}
.sale-header-status-complete{
  padding-left: 5px;
}
.sale-header-status-cancel{
  padding-left: 5px;
  color:#ffc7d0;
}
.sale-header-status-error{
  padding-left: 5px;
  color:#ffc7d0;
}
.sale-payment-status-ok{
  color:#00913a;
}
.sale-payment-status-pending{
  color:#8f0117;
}
.sale-info-section{
  max-width:400px;
  width: 100%;
  border-right: 1px solid #eeeeee;
  margin-right: 20px;
}
.sale-info-section-2{
  max-width:500px;
  width: 100%;
  border-right: 1px solid #eeeeee;
}
.sale-info-box{
  display: flex;
  margin-top: 10px;
}
.sale-buttons{
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
}

/* CUSTOMER */
.customer-info-box{
  display: flex;
  margin-top: 10px;
}
.customer-buttons{
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
}
.customer-info-section{
  max-width:300px;
  width: 100%;
  border-right: 1px solid #eeeeee;
  margin-right: 20px;
}

/* CATEGORY */
.category-info-box{
  display: flex;
  margin-top: 10px;
}
.category-buttons{
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
}
.category-info-section{
  width: 100%;
}

/* ПРЕДЗАКАЗЫ */
.orders-filter-print{
  width: 100%;
  padding: 15px;
}
.orders-filter-dic{
  border-bottom: 1px solid black;
  text-align: center;
}
.orders-filter-div{
  display: flex;

  margin-bottom: 10px;
}
.orders-filter-num{
  border-bottom: 1px dashed black;
}
/* ЧЕК */
.cheque-80mm{
  max-width: 80mm;
  border: 1px solid black;
  padding: 5px;
  padding-top: 20px;
}
.cheque-80mm-no-border{
  max-width: 80mm;
  padding: 7px;
  padding-top: 20px;
  color: #000000 !important;
}
.cheque-title-80mm{
  margin: 0 auto;
  width: 100%;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  text-align: center;
  font-size: 1.9em;
}
.cheque-field_1-80mm{
  width: 100%;
  padding-top: 5px;
  font-size: 1.0em;
  margin: 0 auto;
  text-align: center;
}
.cheque-field_2-80mm{
  width: 100%;
  padding-top: 0px;
  font-size: 1.0em;
  margin: 0 auto;
  text-align: center;
}
.cheque-field_3-80mm{
  width: 100%;
  padding-top: 0px;
  padding-bottom: 10px;
  font-size: 1.0em;
  margin: 0 auto;
  text-align: center;
}
.cheque-field_4-80mm{
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 1.2em;
  margin: 0 auto;
  text-align: center;
}
.cheque-date-80mm{
  border-top: 1px solid black;
  padding-top: 10px;
  width: 100%;
  margin: 0 auto;
  text-align: left;
}
.cheque-number-80mm{
  width: 100%;
  margin: 0 auto;
  text-align: left;
}
.cheque-user-80mm{
  width: 100%;
  margin: 0 auto;
  text-align: left;
}
.cheque-table-80mm{
  padding-top: 10px;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}
.cheque-table-80mm table{
  width: 100%;
}
.cheque-table-header-80mm{
  border-bottom: 2px solid black;
}
.cheque-table-product-quantity-80mm{
  text-align: right;
}
.cheque-table-product-price-80mm{
  text-align: right;
}
.cheque-table-sum-80mm{
  border-top: 1px solid black;
}
.cheque-table-total-80mm{
  border-top: 2px solid black;
  font-weight: bold;
}
.cheque-customer-80mm table{
  padding-top: 10px;
  width: 90%;
  max-width: 90%;
  margin: 0 auto;
  font-size: 0.9em;
  margin-bottom: 20px;
}
.cheque-customer-80mm table td:nth-child(2){
font-weight: bold;
}
.content-order-header{
  margin-top: 20px;
  flex: 1;
  margin-bottom: 10px;
}
.content-order{
  flex: 1;
}
/* .order-content-table{
  max-width: 600px;
} */
.order-content-table tr:nth-child(even) {background-color: #ebebeb;}

.baseWindow{
  background-color:#eeeeee !important;
}



.cheque-table-80mm-div-h{
  display: flex;
  border-bottom: 2px solid black;
}
.cheque-table-80mm-div{
  display: flex;
  border-bottom: 1px dashed black;
}
.cheque-table-80mm-div-itogo{
  display: flex;
  border-top: 1px solid black;
  border-bottom: 1px dashed black;
}
.cheque-table-80mm-div-sub{
  display: flex;
  border-bottom: 1px dashed black;
}
.cheque-table-80mm-div-extra{
  display: flex;
  width: 90%;
  margin: 0 auto;
}
.cheque-table-80mm-div-1{
  flex-grow: 1;
  font-size: 0.9em;
}
.cheque-table-80mm-div-options{
  font-size: 0.7em;
}
.cheque-table-80mm-div-2{
  text-align: right;
  width: 12%;
  margin-left: auto;
  font-size: 0.9em;
}
.cheque-table-80mm-div-3{
  text-align: right;
  width: 19%;
  margin-left: auto;
  font-size: 0.9em;
}
.cheque-table-80mm-div-4{
  text-align: right;
  width: 40%;
  margin-left: auto;
}
.cheque-table-80mm-div-6{
  text-align: left;
  width: 40%;
  margin-left: auto;
}
.cheque-table-80mm-div-7{
  flex-grow: 1;
  text-align: left;
  margin-left: auto;
}

.new-order-header{
  margin-top: 10px !important;
}
tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff;
}

tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}
.list-print{
  width: 100% !important;
}
.list-print-item{
  border-bottom: 1px solid #eeeeee;
  font-size: 10px;
}

.hungry-add-btn{
  background-color: #353535 !important;
  border-radius: 8px !important;
  border-color: #9FBE34 !important;
  border: 2px solid;
  font-weight: 200 !important;
}